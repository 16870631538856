<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: "app",
	components: {},
}
</script>

<style>
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
	background-color: rgba(245, 245, 245, 0.1) !important;
}
</style>
